<template>
  <div class="brand_box">
    <mescroll :up="mescrollUp" :down="mescrollDown">
      <div slot="scrollList" class="scrollList">
        <van-sticky>
          <div class="brand_top">
            <img :src="listBanner[0].url" alt="">
          </div>
        </van-sticky>

        <div class="brand_list_all">
          <div v-for="(item,index) in listDatar" :key="index" class="brand_list" @click="btnDatar(item)">
            <img :src="item.brandImg" alt="">
          </div>
        </div>
      </div>
    </mescroll>

  </div>
</template>

<script>
import { getBrands } from '@/services/api'
import mescroll from '@/component/fresh_scroll/mescroll'

export default {
  name: 'BrandList',
  components: {
    mescroll
  },
  data() {
    return {
      listBanner: '',
      listDatar: '',

      mescrollDown: {
        use: true,
        // top:'50px',
        bottom: '0'
      },
      mescrollUp: {
        initCallBack: this.mescrollInit,
        callback: this.upCallback, // 上拉回调,此处可简写; 相当于 callback: function (page, mescroll) { getListData(page); }
        page: {
          num: 0, // 当前页码,默认0,回调之前会加1,即callback(page)会从1开始
          size: '' // 每页数据的数量
        },
        noMoreSize: 5, // 如果列表已无数据,可设置列表的总数量要大于等于5条才显示无更多数据;避免列表数据过少(比如只有一条数据),显示无更多数据会不好看
        lazyLoad: {
          use: true // 是否开启懒加载,默认false
        }
      }
    }
  },
  mounted() {
    this.GetBrandsData()
  },
  methods: {
    // list
    GetBrandsData: function() {
      const data = {
        page: 1,
        pageSize: 10
      }
      getBrands(data).then((res) => {
        if (Number(res.code) === 200) {
          this.listBanner = res.banner
          this.listDatar = res.data
        }
      })
    },
    // 跳转 详情
    btnDatar: function(t) {
      // brand  22 自定义的
      this.$router.push({
        path: '/sidebar',
        query: {
          goodTypeId: t.brandId,
          keyword: t.brandName,
          channelId: t.channelId,
          brand: 22
        }
      })
    },

    mescrollInit(data) {
      this.mescroll = data
    },
    upCallback(page, mescroll) {
      // 接口请求数据
      this.getListDataFromNet(
        this.pdType,
        page.num,
        page.size,
        (arr) => {
          // 如果是第一页需手动制空列表
          if (page.num === 1) this.listDatar = []
          // 把请求到的数据添加到列表
          this.listDatar = this.listDatar.concat(arr)
          // 数据渲染成功后,隐藏下拉刷新的状态
          this.$nextTick(() => {
            mescroll.endSuccess(arr.length)
          })
        },
        () => {
          // 联网异常,隐藏上拉和下拉的加载进度
          mescroll.endErr()
        }
      )
    },
    getListDataFromNet: function(pdType, pageNum, pageSize, successCallback, errorCallback) {
      const data = {
        page: pageNum,
        pageSize: pageSize
      }
      getBrands(data).then(res => {
        successCallback(res.data)
      })
    }
  }

}
</script>

<style scoped lang="less">
.brand_box {
  background: #f6f6f6;

  .scrollList {
    background: #f6f6f6;
  }

  .brand_top {
    width: 100%;
    height: 110px;
    background: #04b573;
    position: relative;

    img {
      width: 90%;
      position: absolute;
      left: 50%;
      top: 30px;
      transform: translate(-50%);
    }
  }

  .brand_list_all {
    margin: 80px 12px 0;
    overflow: hidden;
    border-radius: 10px;

    .brand_list {
      width: 115px;
      height: 100px;
      background: #fff;
      margin: 1px;
      float: left;
      position: relative;
      overflow: hidden;

      img {
        padding: 12px;
        left: 50%;
        top: 50%;
        position: absolute;
        transform: translate(-50%, -50%);
      }
    }
  }

}

</style>
